<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocNcmCstIpi.table"
      :enabledActions="false"
      :nameToExport="'Totais NCM x CST IPI'"
    >
      <template v-slot:column>
        <DxColumn data-field="ncm" alignment="right" caption="NCM" />
        <DxColumn data-field="cstipi" alignment="right" caption="CST" />
        <DxColumn
          data-field="vprod"
          data-type="number"
          caption="Valor Total"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
        <DxSummary>
          <DxTotalItem
            column="ncm"
            alignment="left"
            summary-type="count"
            display-format="{0} Registros"
         />
          <DxTotalItem
            column="vprod"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
        </DxSummary>
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>